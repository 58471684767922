html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
  /* Margin bottom by footer height */
  background-color: #faf9f8;
  font-family: "Roboto";
}

.input-group {
  margin-bottom: 16px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  /* Set the fixed height of the footer here */
  background-color: #fedb00;
}

.footer .text-muted {
  margin: 20px 0;
}

.reason-selector {
  margin-bottom: 16px;
}

.reason-selector .show {
  display: block;
}

.reason-selector .dropdown-content {
  top: 0;
  left: 0;
  display: none;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ddd;
  z-index: 1;
  max-height: 350px;
  overflow-y: auto;
}

.reason-selector .dropdown-content #reason {
  top: 0;
  position: sticky;
  width: fit-content;
}

.reason-selector .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.reason-selector .reason-description {
  margin-top: 16px;
}

.reason-selector .btn-group>.btn:hover {
  z-index: unset;
}

.error-alert .glyphicon-exclamation-sign {
  color: #e4002b;
}

.error-alert .modal-body {
  max-height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
}

.error-alert .modal-body .list-group-item.fatal-error {
  border: 1px solid #cc193b;
  margin-bottom: unset;
}

.error-alert .modal-body .list-group-item.fatal-error .warning {
  color: #cc193b;
}

.error-alert .modal-body .list-group-item.warning {
  border: 1px solid #f1b434;
  margin-bottom: unset;
}

.error-alert .modal-body .list-group-item.warning .warning {
  color: #f1b434;
}

.review-backup .modal-body .panel-default,
.review-farmacias .modal-body .panel-default,
.review-denylist .modal-body .panel-default {
  max-height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
}

.review-backup .modal-body th,
.review-farmacias .modal-body th,
.review-denylist .modal-body th {
  white-space: nowrap;
}

.finish-backup .glyphicon-ok-circle,
.finish-farmacia .glyphicon-ok-circle,
.finish-denylist .glyphicon-ok-circle,
.finish-protocol .glyphicon-ok-circle {
  color: #00b140;
  font-size: 32px;
  margin-right: 8px;
  vertical-align: bottom;
}

.badge {
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 11px;
  padding: 4px 8px;
}

.badge.cancelled,
.badge.closed {
  color: #e4002b;
  background-color: rgba(228, 0, 43, 0.2);
}

.badge.partial,
.badge.open {
  color: #009cde;
  background-color: rgba(0, 156, 222, 0.2);
}

.badge.complete,
.badge.finished {
  color: #00b140;
  background-color: rgba(0, 177, 64, 0.2);
}

.badge.expired {
  color: #f1b434;
  background-color: rgba(241, 180, 52, 0.2);
}

.badge.device-status {
  color: #605e5c;
  border: 1px solid #605e5c;
  background-color: white;
}

.search-protocol {
  background-color: #fedb00;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 0;
}

.search-protocol .search_protocol-group {
  width: 100%;
}

.search-protocol .search_protocol-group #protocol_search {
  width: 278px;
  font-size: 16px;
  margin: auto;
}

.search-protocol .search_protocol-group .glyphicon-search {
  color: #281e78;
  margin-left: -32px;
  font-size: 18px;
  top: 4px;
  cursor: pointer;
}

.navbar {
  padding: 10px 0;
  background-color: white;
  margin-bottom: 0;
  border: 0;
}

.navbar .logout_btn {
  border: 1px solid #231b60;
  border-radius: 30px;
  margin-top: 3px;
}

.navbar .logout_btn a {
  padding: 10px;
}

.navbar .logout_btn a .glyphicon {
  font-size: 18px;
  top: 0;
}

.navbar .navbar-nav .glyphicon {
  color: #231b60;
}

.navbar.supervisor {
  background-color: #231b60;
}

.navbar.supervisor .logout_btn {
  border: 1px solid white;
}

.navbar.supervisor .navbar-nav .glyphicon {
  color: white;
}

.navbar.abbott {
  background-color: #fedb00;
}

.navbar.abbott .logout_btn {
  border: 1px solid #281e78;
}

.navbar.abbott .navbar-nav .glyphicon {
  color: #281e78;
}

.navbar-brand {
  padding: 0 15px;
}

.navbar-brand .logo-freestyle-libre {
  width: 204px;
  height: 48px;
}

.navbar-brand .logo-abbott {
  width: 112px;
  height: 28px;
  margin-top: 11px;
}

.container {
  position: relative;
  /* max-width: 1366px; */
}

.container.page-content {
  margin-bottom: 120px;
}

.container.home .space-group {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.container.home .space-group a {
  margin: 25px 1rem 0 0;
}

.container.home .space-group a:hover {
  text-decoration: none;
}

.container.home .space-group a:hover .user-space .space-icon {
  color: #281e78;
}

.container.home .space-group a .user-space {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0;
}

.container.home .space-group a .user-space .space-name {
  display: flex;
  margin: 24px 0 24px 14px;
  font-size: 22px;
  color: #605e5c;
  width: 268px;
  white-space: pre-wrap;
  text-align: left;
  line-height: 22px;
  font-weight: bold;
  min-height: 60px;
  align-items: center;
}

.container.home .space-group a .user-space .space-icon {
  color: #605e5c;
  font-size: 32px;
  display: flex;
  align-items: center;
}

.container.home .space-group a .user-space .space-icon span {
  display: block;
  margin: auto 0 auto 15px;
}

.container.home .space-group a .user-space .space-arrow {
  display: flex;
  background-color: #281e78;
  color: white;
  align-items: center;
}

.container.home .space-group a .user-space .space-arrow span {
  display: block;
  margin: auto 8px auto 15px;
}

.container.backup .userfile-name,
.container.farmacias .userfile-name,
.container.denylist .userfile-name {
  margin-left: 12px;
}

.container.backup .main-panel,
.container.farmacias .main-panel,
.container.denylist .main-panel {
  max-width: 416px;
}

.container.backup .farmacias-file .panel-body,
.container.backup .denylist-file .panel-body,
.container.farmacias .farmacias-file .panel-body,
.container.farmacias .denylist-file .panel-body,
.container.denylist .farmacias-file .panel-body,
.container.denylist .denylist-file .panel-body {
  display: flex;
  flex-direction: row;
  padding: 26px;
  justify-content: space-between;
}

.container.backup .farmacias-file .panel-body .glyphicon-file,
.container.backup .denylist-file .panel-body .glyphicon-file,
.container.farmacias .farmacias-file .panel-body .glyphicon-file,
.container.farmacias .denylist-file .panel-body .glyphicon-file,
.container.denylist .farmacias-file .panel-body .glyphicon-file,
.container.denylist .denylist-file .panel-body .glyphicon-file {
  color: #00b140;
  font-size: 32px;
}

.container.backup .farmacias-file .panel-body .last-file,
.container.backup .denylist-file .panel-body .last-file,
.container.farmacias .farmacias-file .panel-body .last-file,
.container.farmacias .denylist-file .panel-body .last-file,
.container.denylist .farmacias-file .panel-body .last-file,
.container.denylist .denylist-file .panel-body .last-file {
  border: 1px solid #281e78;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  width: 40px;
}

.container.backup .farmacias-file .panel-body .last-file span,
.container.backup .denylist-file .panel-body .last-file span,
.container.farmacias .farmacias-file .panel-body .last-file span,
.container.farmacias .denylist-file .panel-body .last-file span,
.container.denylist .farmacias-file .panel-body .last-file span,
.container.denylist .denylist-file .panel-body .last-file span {
  left: 6px;
  top: 6px;
}

.container.backup .farmacias-file .panel-body p,
.container.backup .denylist-file .panel-body p,
.container.farmacias .farmacias-file .panel-body p,
.container.farmacias .denylist-file .panel-body p,
.container.denylist .farmacias-file .panel-body p,
.container.denylist .denylist-file .panel-body p {
  margin: auto auto auto 20px;
}

.container.backup .farmacias-file .panel-body .updated-date,
.container.backup .denylist-file .panel-body .updated-date,
.container.farmacias .farmacias-file .panel-body .updated-date,
.container.farmacias .denylist-file .panel-body .updated-date,
.container.denylist .farmacias-file .panel-body .updated-date,
.container.denylist .denylist-file .panel-body .updated-date {
  font-size: 24px;
}

.container.backup .farmacias-file .panel-body .glyphicon-download-alt,
.container.backup .denylist-file .panel-body .glyphicon-download-alt,
.container.farmacias .farmacias-file .panel-body .glyphicon-download-alt,
.container.farmacias .denylist-file .panel-body .glyphicon-download-alt,
.container.denylist .farmacias-file .panel-body .glyphicon-download-alt,
.container.denylist .denylist-file .panel-body .glyphicon-download-alt {
  font-size: 24px;
}

.container.search .select-a-card {
  color: #b3b0ad;
  padding: 40px;
}

.container.search .search-instructions {
  color: #b3b0ad;
  padding: 40px;
}

.container.search .search-no-result {
  color: #e4002b;
  padding: 40px;
  text-align: center;
}

.container.search h1,
.container.search h2,
.container.search h3,
.container.search h4 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.container.search .protocol-overview {
  border-bottom: 1px solid #b3b0ad;
}

.container.search .protocol-overview .protoco-status {
  text-align: center;
}

.container.search .withdrawer-info {
  border-top: 1px solid #b3b0ad;
}

.container.search .withdrawer-info h3 {
  margin-top: 32px;
}

.container.search .devices-info {
  border-top: 1px solid #b3b0ad;
}

.container.search .devices-info h3 {
  margin-top: 32px;
}

.container.search .exchange-info {
  border-top: 1px solid #b3b0ad;
}

.container.search .exchange-info h3 {
  margin-top: 32px;
}

.container.search .protocol-info-head {
  padding-top: 36px;
  padding-left: 25px;
  background-color: white;
}

.container.search .protocol-info-data {
  padding-top: 52px;
  padding-left: 25px;
}

.container.search .reason textarea {
  resize: none;
}

.container.search button {
  margin-top: 16px;
  margin-left: 16px;
}

.container.search .result-list {
  background-color: white;
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #e1dfdd;
  border-top: 0;
  position: sticky;
  top: 0;
  padding: 0;
}

.container.search .result-list::-webkit-scrollbar {
  width: 10px;
}

.container.search .result-list::-webkit-scrollbar-track {
  border-radius: 10px;
}

.container.search .result-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 1px solid white;
  background-color: #c8c6c4;
}

.container.search .result-list .protocol-list-heading {
  margin: 16px;
}

.container.search .result-list .search-protocol {
  top: 0;
  left: 0;
  position: sticky;
  width: 320px;
  margin: 0;
}

.container.search .result-list .protocol-list-item {
  cursor: pointer;
  width: calc(100% - 24px);
  border: 1px solid #e1dfdd;
  border-radius: 4px;
  padding: 16px;
  margin: 16px;
  order: 2;
}

.container.search .result-list .protocol-list-item.selected {
  border: 1px solid #281e78;
  background-color: #faf9f8;
}

.container.search .result-list .protocol-list-item .badge {
  float: right;
  margin-top: -20px;
}

.container.search .result-list .protocol-list-item .protocol-label {
  color: #b3b0ad;
  margin-bottom: 24px;
}

.container.search .result-list .protocol-list-item .protocol-label .number {
  color: #605e5c;
  font-size: 20px;
}

.container.search .result-list .protocol-list-item .protocol-name {
  margin-bottom: 0;
  font-weight: bold;
}

.container.search .result-list .protocol-list-item .protocol-document {
  margin-bottom: 0;
}

.container.search .result-list .protocol-list-item .protocol-document span {
  color: #b3b0ad;
}

.container.search .show-protocol {
  margin: 0 16px 120px 16px;
}

.container.search .show-protocol .row {
  margin-bottom: 32px;
}

.container.search .show-protocol .row label {
  font-size: 16px;
}

.container.search .show-protocol .row p:not(.text-muted) {
  font-size: 20px;
}

.container.search .show-protocol .row p.reason-description {
  font-size: 16px;
}

.container.search .show-protocol .withdraw {
  padding-left: 48px;
  border-left: 1px solid #c8c6c4;
}

.container.search .show-protocol .history {
  font-size: 16px;
  color: #b3b0ad;
  text-align: right;
}

.container.search .show-protocol .history span {
  font-size: 20px;
  color: black;
  margin-left: 10px;
}

.container.search .show-protocol .exchange-history .row {
  margin: 0;
}

.container.search .show-protocol .exchange-history .row h4 {
  margin-bottom: 32px;
}

.container.search .show-protocol .exchange-history .row.drugstore-info-heading {
  padding-top: 32px;
  margin-top: 32px;
  border-top: 1px solid #c8c6c4;
}

.container.search .show-protocol .exchange-history .panel-heading {
  padding: 16px;
}

.container.search .show-protocol .exchange-history .panel-heading .row h4 {
  margin: 0;
}

.container.search .show-protocol .exchange-history .panel-body>h4 {
  margin-bottom: 32px;
}

.container.search .show-protocol .exchange-history .panel-body .row.device-info {
  margin-bottom: 16px;
}

.container.add-devices .no-devices-added {
  color: #b3b0ad;
  margin-top: 51px;
  padding: 32px 16px 56px 16px;
}

.container.add-devices .remove_btn {
  padding: 3px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-top: 18px;
  color: #e4002b;
  border: 1px solid #c8c6c4;
  cursor: pointer;
}

.container.add-devices .device-wrapper {
  width: 80%;
  background-color: #f3f2f1;
  padding: 0 24px 34px 24px;
}

.container.add-devices .device-wrapper>h4 {
  border-bottom: 1px solid #b3b0ad;
  padding: 24px 16px 16px 16px;
  margin-top: 0;
}

.container.add-devices .device-info {
  padding: 16px;
  margin-top: 32px;
  padding-bottom: 16px;
  background-color: white;
  border-radius: 4px;
}

.container.add-devices .device-info .dropdown-device-type button {
  min-height: 62px;
}

.container.add-devices .device-info .edit_btn {
  margin-top: 18px;
}

.container.add-devices .device-info .qty-group button,
.container.add-devices .device-info .qty-group input {
  height: 35px;
}

.container.add-devices .device-info .qty-group .qty {
  min-width: 62px;
  text-align: center;
}

.container.add-devices .device-info .remove_btn {
  float: right;
}

.container.add-devices .input-group {
  width: 100%;
}

.container.add-devices .controls {
  margin: 32px 0 0 0;
  border-bottom: 1px solid #b3b0ad;
}

.container.add-devices .controls #back_btn {
  margin-right: 42px;
}

.container.add-devices .controls .plus-one {
  padding: 18px 24px;
  color: #281e78;
}

.container.add-devices .controls .plus-one .glyphicon {
  margin-right: 8px;
}

.container.summary .input-group {
  width: 100%;
}

.container.summary hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.container.summary .row {
  margin-bottom: 32px;
}

.container.summary .row strong {
  font-size: 16px;
}

.container.summary .row p {
  font-size: 20px;
  color: #605e5c;
}

.container.summary h3 {
  margin: 40px 0;
  color: #605e5c;
}

.container.summary .withdrawer-info p {
  font-size: 20px;
}

.container.summary .customer-info p {
  font-size: 20px;
}

.container.summary .devices-info p {
  font-size: 20px;
}

.container.summary .devices-info .qty {
  text-align: center;
}

.container.summary .devices-info .qty .quantity {
  background-color: #f3f2f1;
  border-radius: 8px;
}

.container.summary .devices-info .description {
  padding-left: 35px;
}

.container.summary .devices-info .description .reason-description {
  font-size: 16px;
}

.container.withdrawer-info #submit_btn,
.container.withdrawer-info #back_btn {
  margin-top: 24px;
}

.container.withdrawer-info .who-withdrawer-group label {
  font-size: 20px;
  margin-bottom: 24px;
}

.container.withdrawer-info .who-withdrawer-group input[type=radio]:not(:first-of-type) {
  margin-left: 24px;
}

.container.withdrawer-info .input-group {
  width: 100%;
}

.container.withdrawer-info.edit .controls {
  margin: 32px 0 0 0;
}

.container.withdrawer-info.edit .controls #back_btn {
  margin-right: 42px;
}

.container.withdrawer-info.edit .disabled {
  opacity: 0.5;
}

.container.start-protocol .input-group {
  width: 100%;
}

.container.start-protocol #submit_btn {
  margin-top: 24px;
}

.container.login {
  max-width: 560px;
}

.container.login .input-group {
  width: 100%;
}

.container.login .panel .panel-heading {
  padding: 32px 96px;
}

.container.login .panel .panel-body {
  padding: 32px 96px;
}

.container.login #message-box {
  margin-top: 32px;
}

.container .protocol-step {
  color: #605e5c;
  margin-bottom: 48px;
}

.has-error {
  border-color: #e4002b;
  color: #e4002b;
}

.has-error input,
.has-error button {
  border-color: #e4002b;
  color: #e4002b;
}

.alert-warning {
  background-color: #f1b434;
  color: #1b1a19;
  border-color: #f1b434;
}

.alert.suspicious-protocol {
  max-width: 320px;
  position: absolute;
  top: 20px;
  right: 0;
}

.reasons_template {
  display: none;
}

.btn-default:not(.dropdown-toggle):not(.user-space):not(.qty-minus):not(.qty-plus) {
  border-color: #281e78;
  color: #281e78;
}

.open>.dropdown-menu {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

.text-muted {
  color: #777777;
}